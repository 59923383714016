import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormType } from '../utils/helpers';

const ResetPasswordHandler = ({ openModal }) => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      openModal(FormType.RESET_PASSWORD);
    }
    // Redirect to home or another page after setting the form type
    navigate('/');
  }, [token, openModal, navigate]);

  return null;
};

export default ResetPasswordHandler;