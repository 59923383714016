import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { useUser } from './UserContext';

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
    const [favoritedListings, setFavoritedListings] = useState(new Set());
    const { user } = useUser();

    useEffect(() => {
        const fetchFavoritedItems = async () => {
            try {
                if (user && user.id) {
                    const apiUrl = process.env.REACT_APP_API_SERVICE_URL
                    const response = await axios.get(`${apiUrl}/listings/favorites/${user.id}`);
                    setFavoritedListings(new Set(response.data.listings));
                } else {
                    setFavoritedListings(new Set());
                }
            } catch (error) {
                setFavoritedListings(new Set());
            }
        };
        fetchFavoritedItems();
    }, [user]);

    const addFavorite = async (listing) => {
        if (!user || !user.id) {
            return false;
        }
        try {
            const listingId = listing._id;
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.post(`${apiUrl}/listings/favorites/${user.id}`, { listingId });
            if (response.data.status === "ok") {
                setFavoritedListings(prev => new Set(prev).add(listing));
                return true;
            } else if (response.data.status === "already favorited") {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const removeFavorite = async (listing) => {
        if (!user || !user.id) {
            // Should never reach this point
            return false;
        }
        try {
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.delete(`${apiUrl}/listings/favorites/${user.id}/${listing._id}`);
            if (response.data.status === "ok") {
                setFavoritedListings(prev => {
                    const newSet = new Set(prev);
                    for (let item of newSet) {
                        if (item._id === listing._id) {
                            newSet.delete(item);
                            break;
                        }
                    }
                    return newSet;
                });
            } else if (response.data.status === "not found in favorites") {
                return false;
            }
        } catch (error) {
            return false;
        }
    };

    const determineIfFavorited = (listing) => {
        // Compare by _id
        if (!listing || !listing._id || !user || !user.id) {
            return false;
        }
        for (let item of favoritedListings) {
            if (item._id === listing._id) {
                return true;
            }
        }
        return false;
    };

    return (
        <FavoritesContext.Provider value={{ favoritedListings, setFavoritedListings, addFavorite, removeFavorite, determineIfFavorited }}>
            {children}
        </FavoritesContext.Provider>
    );
};

export default FavoritesContext;
