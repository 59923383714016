import React from 'react';
import ListingsGridCard from './ListingsGridCard';
import './css/ListingsGrid.css'; // Create this file for ListingGrid specific styles

const ListingsGrid = ({ onSingleListingClick, listings, totalPages, currentPage, onPageChange, openLoginRegistrationModal }) => {
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <div className="listings-grid-container">
      <div className="listings-grid">
        {Array.isArray(listings) && listings.length > 0 ? (
          listings.map(listing => (
            <ListingsGridCard 
              key={listing.sku} 
              listing={listing}
              onClick={() => onSingleListingClick(listing)}
              openLoginRegistrationModal={openLoginRegistrationModal}
            />
          ))
        ) : (
          <p className='empty-listings-grid'>No listings found</p>
        )}
      </div>
      <div className="pagination">
        <button className="previous-button" onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
        <span>Page {currentPage} of {totalPages}</span>
        <button className="next-button" onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
      </div>
    </div>
  );
};

export default ListingsGrid;
