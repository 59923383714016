import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './css/FilterDropdown.css';

const FilterDropdown = ({ label, options, selectedOptions, setSelectedOptions, minMaxPrice, setMinMaxPrice, setSortOrder, showOptions, toggleDropdown }) => {
  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSortChange = (option) => {
    setSortOrder(option);
  };

  const handleMinMaxChange = (event) => {
    const { name, value } = event.target;
    setMinMaxPrice(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="filter-dropdown">
      <button className="filter-button" onClick={toggleDropdown}>
        {label} <span className={`arrow ${showOptions ? 'expanded toggle-button' : 'toggle-button'}`}> {'>'} </span>
      </button>
      <CSSTransition
        in={showOptions}
        timeout={300}
        classNames="dropdown"
        unmountOnExit
      >
        <div className="dropdown-content">
          {label === "Price" ? (
            <div className="min-max-inputs">
              <label>
                Min:
                <input
                  type="number"
                  name="min"
                  value={minMaxPrice.min}
                  onChange={handleMinMaxChange}
                  placeholder="Min Price"
                  step="100"
                />
              </label>
              <label>
                Max:
                <input
                  type="number"
                  name="max"
                  value={minMaxPrice.max}
                  onChange={handleMinMaxChange}
                  placeholder="Max Price"
                  step="100"
                />
              </label>
            </div>
          ) : label === "Sort By" ? (
            <ul>
              {options.map((option, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="radio"
                      name="sortOptions"
                      checked={selectedOptions.includes(option)}
                      onChange={() => handleSortChange(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              {options.map((option, index) => (
                <li key={index}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes(option)}
                      onChange={() => handleOptionChange(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default FilterDropdown;