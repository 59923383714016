import React from 'react';
import { formatPrice } from '../utils/helpers';
import './css/SingleListingModalComp.css';

const SingleListingModalComp = ({ listingComp, originalListingPrice, handleClick }) => {
    let compPrice;
    if (listingComp.status === 'sold') {
        compPrice = listingComp.sold_price ? listingComp.sold_price : listingComp.price_history[listingComp.price_history.length - 1];
    } else {
        compPrice = listingComp.current_price;
    }

    const priceDifference = originalListingPrice - compPrice;

    let priceDeltaNote;
    let priceDeltaColor;
    if (listingComp.status === 'sold') {
        priceDeltaNote = "Sold for ";
        if (priceDifference < 0) {
            priceDeltaNote += formatPrice(priceDifference * -1) + " more";
        } else {
            priceDeltaNote += formatPrice(priceDifference) + " cheaper";
        }
        priceDeltaColor = priceDifference < 0 ? 'green' : priceDifference > 0 ? 'red' : 'inherit';
    } else {
        priceDeltaNote = priceDifference > 0 ? 'You\'d Save ' : priceDifference < 0 ? 'You\'d Lose ' : '-';
        priceDeltaNote += formatPrice(priceDifference);
        priceDeltaColor = priceDifference < 0 ? 'red' : priceDifference > 0 ? 'green' : 'inherit';
    }
    

    // If the price difference is negative, the user would lose money so we want to show the price difference in red
    // If the price difference is positive, the user would save money so we want to show the price difference in green
    // If the price is the same, we don't want to the normal color

    const isSoldOrOnHold = listingComp.status !== 'available';
    return (
        <div onClick={() => handleClick(listingComp)} className="comp">
            <img src={listingComp.s3_image_urls[0]} className="comp-image" alt="Listing" />
            <div className="comp-details">
                {isSoldOrOnHold && (
                    <div className="comp-status-banner">
                        {listingComp.status === 'sold' ? 'Sold' : 'On Hold'}
                    </div>
                )}
                <p className="comp-price">Price: {formatPrice(compPrice)}</p>
                <p className="comp-price-delta" style={{ color: priceDeltaColor}}>
                    {priceDifference !== 0 ? priceDeltaNote : ''}
                </p>
            </div>
        </div>
    );
};

export default SingleListingModalComp;
