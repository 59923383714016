import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IosShareIcon from '@mui/icons-material/IosShare';
import { IconButton } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import Modal from 'react-modal';
import { BarLoader } from 'react-spinners';
import { CSSTransition } from 'react-transition-group';
import urlJoin from 'url-join';
import FavoritesContext from '../contexts/FavoritesContext';
import { useUser } from '../contexts/UserContext';
import { formatPrice, NUM_SEE_COMPS_TRIGGERED_THRESHOLD, toTitleCase } from '../utils/helpers';
import ConditionScale from './ConditionScale';
import './css/SingleListingModal.css';
import SingleListingModalComp from './SingleListingModalComp';
import SingleListingModalDescription from './SingleListingModalDescription';

const SingleListingModal = ({ isOpen, onRequestClose, listing, openLoginRegistrationModal }) => {
    const { user } = useUser();
    const {addFavorite, removeFavorite, determineIfFavorited } = useContext(FavoritesContext);
    let favorited = determineIfFavorited(listing);
    const [isCompSetExpanded, setIsCompSetExpanded] = useState(false);
    const [listingComps, setListingComps] = useState([]);
    const [selectedListing, setSelectedListing] = useState(listing);
    const [isFavorited, setIsFavorited] = useState(favorited);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(true);
    const [isFavoriteHovered, setIsFavoriteHovered] = useState(false);
    const [isFetchingComps, setIsFetchingComps] = useState(false);

    useEffect(() => {
        setSelectedListing(listing);
        setIsFavorited(determineIfFavorited(listing));
    }, [listing]);

    useEffect(() => {
        setIsFavorited(favorited);
    }, [favorited]);

    // Fetch comps when the modal opens
    useEffect(() => {
        if (isOpen) {
            fetchComps();
        }
    }, [isOpen, selectedListing]);

    const handleFavoriteClick = async (e) => {
        e.stopPropagation();
        if (isFavorited) {
            if (await removeFavorite(selectedListing)) {
                setIsFavorited(false);
            }
        } else {
            if (await addFavorite(selectedListing)) {
                setIsFavorited(true);
            } else {
                setIsFavoriteHovered(false);
                handleCloseModal();
                openLoginRegistrationModal();
            }
        }
    };

    const handleShareClick = () => {
        const shareUrl = `${window.location.origin}/?listingId=${listing._id}`;
        navigator.clipboard.writeText(shareUrl).then(() => {
            alert('Link copied to clipboard!');
        });
    };

    // Create a ref for the image container
    const imageContainerRef = useRef(null);

    const fetchComps = async () => {
        if (!selectedListing) return;
        try {
            setIsFetchingComps(true);
            const params = {
                sku: selectedListing.sku,
                productVariantId: selectedListing.product_variant_id,
                standardizedCondition: selectedListing.standardized_condition,
            };
            const queryString = new URLSearchParams(params).toString();
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const url = urlJoin(`${apiUrl}/comps`, `?${queryString}`);
            const response = await fetch(url);
            const data = await response.json();
            const sortedComps = data["comps"].sort((a, b) => {
                // Sort from lowest to highest price
                if (a.current_price < b.current_price) {
                    return -1;
                }
            });
            setListingComps(sortedComps);
        } catch (error) {
            setListingComps([]);
        } finally {
            setIsFetchingComps(false);
        }
    };
    
    const handleExpandCompsClick = () => {
        if (!localStorage.getItem('num_see_comps_triggered')) {
            localStorage.setItem('num_see_comps_triggered', 1);
        } else if (!user && !isCompSetExpanded) { // User not logged in + opening comps collapsible
            const numSeeCompsTriggered = parseInt(localStorage.getItem('num_see_comps_triggered'));
            if (numSeeCompsTriggered <= NUM_SEE_COMPS_TRIGGERED_THRESHOLD){
                localStorage.setItem('num_see_comps_triggered', numSeeCompsTriggered + 1);
            } else {
                // Prompt user to create an account
                handleCloseModal();
                openLoginRegistrationModal();
                return;
            }
        }
        setIsCompSetExpanded(!isCompSetExpanded);
        // Collapse SingleListingModalDescription
        setIsDescriptionExpanded(false);
    };

    const handleCompClick = (comp) => {
        // Scroll to the top of the image container when the selected listing changes
        if (imageContainerRef.current) {
            imageContainerRef.current.scrollTop = 0;
        }
        setSelectedListing(comp);
        setIsFavorited(determineIfFavorited(comp));
        setIsFavoriteHovered(false);
        setIsCompSetExpanded(false);
        setIsDescriptionExpanded(true);
    };

    const handleCloseModal = () => {
        setListingComps([]);
        setIsCompSetExpanded(false);
        setSelectedListing(null);
        setIsFavoriteHovered(false);
        onRequestClose();
    };

    if (!selectedListing) return null;

    let productTitle = toTitleCase(selectedListing.product.model)+ " Bag"
    if (selectedListing.product_variant.size_name) {
        productTitle += " in Size " + toTitleCase(selectedListing.product_variant.size_name.trim());
    }
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleCloseModal}
            contentLabel="Listing Details"
            className="small-modal"
            overlayClassName="modal-overlay"
        >
            <div className="modal-container">
                <button className="close-button" onClick={handleCloseModal}>X</button>
                <div className="image-container" ref={imageContainerRef}>
                    {selectedListing.s3_image_urls.map((url, index) => (
                        <LazyLoad key={index} height={200} offset={100}>
                            <a href={selectedListing.listing_url} target="_blank" rel="noopener noreferrer">
                                <img src={url} alt={`Listing image ${index + 1}`} className="listing-image" />
                            </a>
                        </LazyLoad>
                    ))}
                </div>
                <div className="details-container">
                    <h2>{selectedListing.product.brand}</h2>
                    <p className="product-listing-title">{productTitle}</p>
                    <div className="listing-details">
                        <div className="price-container">
                            <p className="price" style={{ marginBottom: '0px' }}>
                                {formatPrice(selectedListing.current_price)}
                                {selectedListing.price_history.length > 1 && selectedListing.price_history[0] !== selectedListing.current_price && (
                                    <span className="price original-price">
                                        {formatPrice(selectedListing.price_history[0])}
                                    </span>
                                )}
                            </p>
                            <a href={selectedListing.listing_url} target="_blank" rel="noopener noreferrer">
                                <button className="action-button">Go to Listing</button>
                            </a>
                            <div
                                className="favorite-icon-single-listing"
                                onClick={handleFavoriteClick}
                                onMouseEnter={() => setIsFavoriteHovered(true)}
                                onMouseLeave={() => setIsFavoriteHovered(false)}
                            >
                                {isFavorited || isFavoriteHovered ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                            </div>
                            <IconButton onClick={handleShareClick} className="share-button">
                                <IosShareIcon className="share-button" />
                            </IconButton>
                        </div>
                    </div>
                    <p className="availability" style={{ color: selectedListing.status !== 'available' ? 'red' : 'inherit' }}>
                        {toTitleCase(selectedListing.status)}
                    </p>
                    <div className="description">
                        <SingleListingModalDescription
                            listing={selectedListing}
                            isExpanded={isDescriptionExpanded}
                            setIsExpanded={setIsDescriptionExpanded}
                        />
                    </div>
                    <div className="condition">
                        <ConditionScale selectedCondition={toTitleCase(listing.condition_name)} />
                    </div>

                    <div>
                        {isFetchingComps ? (
                            <BarLoader color="#333" />
                        ) : (
                            <>
                                {listingComps.length > 0 ? (
                                    <div className="collapsible-container">
                                        <div onClick={handleExpandCompsClick} className="toggle-button">
                                            <span className="see-comps-toggle">See Comps</span>
                                            <span className={`arrow ${isCompSetExpanded ? 'expanded' : ''}`} style={{ marginLeft: '16px' }}>
                                                {'>'}
                                            </span>
                                        </div>
                                        <CSSTransition
                                            in={isCompSetExpanded}
                                            timeout={300}
                                            classNames="comps"
                                            unmountOnExit
                                        >
                                            <div className="comps-container-scroll">
                                                {listingComps.map((listingComp, index) => (
                                                    <SingleListingModalComp
                                                        key={index}
                                                        listingComp={listingComp}
                                                        originalListingPrice={selectedListing.current_price}
                                                        handleClick={handleCompClick}
                                                    />
                                                ))}
                                            </div>
                                        </CSSTransition>
                                    </div>
                                ) : (
                                    <div>No Comps Found</div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SingleListingModal;
