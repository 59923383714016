import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import LoginRegistrationModal from './components/LoginRegistrationModal';
import { FavoritesProvider } from './contexts/FavoritesContext';
import { UserProvider } from './contexts/UserContext';
import ResetPasswordHandler from './handlers/ResetPasswordHandler';
import ListingsPage from './pages/ListingsPage';
import { FormType } from './utils/helpers';

function App() {
  const [isLoginRegistrationalOpen, setIsLoginRegistrationModalOpen] = useState(false);
  const [formType, setFormType] = useState(FormType.REGISTER);

  const openModal = (type) => {
    setFormType(type);
    setIsLoginRegistrationModalOpen(true);
  };

  const closeModal = () => {
    setIsLoginRegistrationModalOpen(false);
    setFormType(FormType.REGISTER);
  };

  return (
    <UserProvider>
      <FavoritesProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <ListingsPage
                  onLoginClick={() => openModal(FormType.LOGIN)}
                  onRegisterClick={() => openModal(FormType.REGISTER)}
                />
              }
            />
            <Route path="/listings" element={
                <ListingsPage
                  onLoginClick={() => openModal(FormType.LOGIN)}
                  onRegisterClick={() => openModal(FormType.REGISTER)}
                />
              } />
            <Route
              path="/reset_password/:token"
              element={<ResetPasswordHandler openModal={openModal} />}
            />
          </Routes>
          <LoginRegistrationModal
            isOpen={isLoginRegistrationalOpen}
            onClose={closeModal}
            formType={formType}
            setFormType={setFormType}
          />
        </Router>
      </FavoritesProvider>
    </UserProvider>
  );
}

export default App;
