import PropTypes from 'prop-types';
import React from 'react';
import { useUser } from '../contexts/UserContext';
import { FormType } from '../utils/helpers';
import AuthForm from './AuthForm';
import './css/LoginRegistrationModal.css';


const LoginRegistrationModal = ({ isOpen, onClose, formType, setFormType }) => {
  const { login, register, forgotPassword, resetPassword } = useUser();

  const loginFields = [
    { name: 'email', label: 'Email', type: 'email', required: true },
    { name: 'password', label: 'Password', type: 'password', required: true },
  ];

  const registerFields = [
    { name: 'name', label: 'Name', type: 'text', required: true },
    { name: 'email', label: 'Email', type: 'email', required: true },
    { name: 'newPassword', label: 'Password', type: 'password', required: true },
    { name: 'confirmPassword', label: 'Confirm Password', type: 'password', required: true },
  ];

  const forgotPasswordFields = [
    { name: 'email', label: 'Email', type: 'email', required: true },
  ];

  const resetPasswordFields = [
    { name: 'newPassword', label: 'Password', type: 'password', required: true },
    { name: 'confirmPassword', label: 'Confirm Password', type: 'password', required: true },
  ]

  if (!isOpen) {
    return null;
  }

  const renderForm = () => {
    switch (formType) {
      case FormType.LOGIN:
        return (
          <AuthForm
            title="Login"
            fields={loginFields}
            apiCallback={login}
            onSuccess={onClose}
            subtitle="New to Second Sense?"
            toggleForm={() => setFormType(FormType.REGISTER)}
            toggleText="Register Now"
            submissionLabel="Login"
            // extraButtons={[
            //   {
            //     label: 'Forgot password?',
            //     className: 'forgot-password-button',
            //     onClick: () => setFormType(FormType.FORGOT_PASSWORD),
            //   },
            // ]}
          />
        );
      case FormType.REGISTER:
        return (
          <AuthForm
            title="Join Second Sense"
            fields={registerFields}
            apiCallback={register}
            onSuccess={onClose}
            subtitle="Already have an account?"
            toggleForm={() => setFormType(FormType.LOGIN)}
            toggleText="Login Now"
            submissionLabel="Register"
          />
        );
      case FormType.FORGOT_PASSWORD:
        return (
          <AuthForm
            title="Forgot Password"
            fields={forgotPasswordFields}
            apiCallback={forgotPassword}
            onSuccess={onClose}
            subtitle="Please provide your email or"
            toggleForm={() => setFormType(FormType.LOGIN)}
            toggleText="back to login"
            submissionLabel="Submit"
          />
        );
      case FormType.RESET_PASSWORD:
        return (
          <AuthForm
            title="Reset Password"
            fields={resetPasswordFields}
            apiCallback={resetPassword}
            onSuccess={onClose}
            subtitle=""
            toggleForm={() => setFormType(FormType.LOGIN)}
            toggleText="Back to Login"
            submissionLabel="Reset Password"
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {renderForm()}
      </div>
    </div>
  );
};

LoginRegistrationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  formType: PropTypes.oneOf(Object.values(FormType)).isRequired,
  setFormType: PropTypes.func.isRequired,
};

export default LoginRegistrationModal;