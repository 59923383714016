import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Menu, MenuItem } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import FilterBar from '../components/FilterBar';
import ListingsGrid from '../components/ListingsGrid';
import SearchBar from '../components/SearchBar';
import SingleListingModal from '../components/SingleListingModal';
import FavoritesContext from '../contexts/FavoritesContext';
import { useUser } from '../contexts/UserContext'; // Import useUser
import './css/ListingsPage.css'; // Create this file for ListingsPage specific styles

// Set the app element for react-modal
Modal.setAppElement('#root');

const ListingsPage = ({ onLoginClick, onRegisterClick }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const location = useLocation();
  const parsePageNumberFromURL = () => {

      const params = new URLSearchParams(location.search);
      return parseInt(params.get('page') || 1);
  };
  const [brands, setBrands] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(parsePageNumberFromURL());
  const [totalPages, setTotalPages] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const limit = 20; // Number of listings per page
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate
  const { favoritedListings } = useContext(FavoritesContext);
  const [listings, setListings] = useState([]);
  const [isFetchingListings, setIsFetchingListings] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const { user, logout } = useUser(); // Use user and logout from UserContext

  // Helper function to update URL params and navigate
  const updateParams = (key, value) => {
    const params = new URLSearchParams(location.search);
    if (value) {
      params.set(key, value); // Set or update the parameter
    } else {
      params.delete(key); // Remove the parameter if value is null
    }
    navigate({ search: params.toString() }); // Navigate with updated query params
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const listingId = params.get('listingId');
    const searchQueryFromURL = params.get('searchQuery') || '';

    if (listingId && !isModalOpen) {
      fetchListingById(listingId);  // Open modal if listingId is present
    } else if (!listingId && isModalOpen) {
      // Close modal if listingId is removed from the URL
      setIsModalOpen(false);
      setSelectedListing(null);
    }

    if (searchQueryFromURL !== searchQuery) {
      setSearchQuery(searchQueryFromURL);
    }
  }, [location, isModalOpen]);

  // Fetch listing details by ID
  const fetchListingById = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_SERVICE_URL
      const response = await axios.get(`${apiUrl}/listings/${id}`);
      handleSingleListingClick(response.data);
    } catch (error) {
      // Redirect to home page if the listing is not found
      navigate('/');
    }
  };

  // Fetch listings with applied filters and pagination
  const fetchListings = async (params = {}) => {
    setIsFetchingListings(true);
    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    }
    // Create a new cancel token
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let requestCanceled = false; // Flag to track if the request was canceled

    try {
      if (searchQuery.trim() !== '') {
        params.searchQuery = searchQuery;
      }
      const apiUrl = process.env.REACT_APP_API_SERVICE_URL
      const response = await axios.get(`${apiUrl}/listings`, {
        params: {
          page: currentPage,
          limit,
          ...filters,
          ...params,
        },
        cancelToken: source.token, // Attach the cancel token to the request
      });
      setListings(response.data.listings);
      setTotalPages(response.data.totalPages);
      setBrands(response.data.brands);
    } catch (error) {
      if (axios.isCancel(error)) {
        requestCanceled = true;
      } else {
        setListings([]);
      }
    } finally {
      if (!requestCanceled) {
        setIsFetchingListings(false); // Ensure this is only set if the request was not canceled
      }
    }
  };

  useEffect(() => {
    fetchListings();
  }, [currentPage, filters, user, searchQuery]);

  // Handle listing click to open modal and update the URL
  const handleSingleListingClick = (listing) => {
    setSelectedListing(listing);
    setIsModalOpen(true);
    updateParams('listingId', listing._id); // Update URL with listingId
  };

  // Handle filter application and reset page to 1
  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1); // Reset to page 1
    updateParams('page', 1); // Update URL to page 1
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    updateParams('page', newPage); // Update URL with new page
  };

  // Handle search
  const handleSearch = (query) => {
    setCurrentPage(1); // Reset to page 1 when searching
    setSearchQuery(query);
    updateParams('page', 1); // Reset page to 1 in URL
    updateParams('searchQuery', query); // Add search query to URL
  };

  // Handle menu icon click
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle home button click
  const handleHomeClick = () => {
    setFilters({}); // Reset the filters
    // setSearchQuery(''); // Reset the search query
    setCurrentPage(1); // Reset the current page
    updateParams('page', 1); // Reset to page 1 in URL
  };

  // Handle showing favorited listings
  const handleFavoritesClick = () => {
    setCurrentPage(1); // Reset the current page
    updateParams('page', 1); // Set page to 1 in URL
    setTotalPages(1); // Reset the total pages
    setListings(Array.from(favoritedListings)); // Show favorite listings
  };

  const handleHover = () => {
    setAnchorEl(anchorEl); // Keep the menu open
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the menu
  };

  // Handle logout click
  const handleLogoutClick = () => {
    logout(); // Logout the user
    handleClose(); // Close the menu
    navigate('/'); // Redirect to home page
  };

  // Close the modal and remove listingId from the URL
  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedListing(null); // Clear selected listing
    updateParams('listingId', null); // Remove listingId from URL
  };

  const openLoginFromSingleListingModal = () => {
    closeModal(); // Close the modal first
    onRegisterClick(); // Open registration/login modal
  };

  return (
    <div className="container">
      {/* Page title */}
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img onClick={handleHomeClick} src={`${process.env.PUBLIC_URL}/title_logo.png`} alt="SecondSense Logo" style={{'marginTop': "10px"}}/>

      </Link>

      {/* Search bar and icon container */}
      <div className="search-bar-container">
        <SearchBar onSearch={handleSearch} initialQuery={searchQuery}/>
        <IconButton
          color="inherit"
          aria-label="person"
          sx={{ fontSize: 50 , marginRight: 5}}
          onMouseEnter={handleIconClick}>
          <PersonIcon fontSize='inherit'/>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            onMouseEnter: handleHover,
            onMouseLeave: handleClose,
          }}
        >
          {user ? (
            [
              <MenuItem key="home" onClick={handleHomeClick} sx={{ fontFamily: 'Quicksand, sans-serif', fontWeight: 450 }}>Home</MenuItem>,
              <MenuItem key="favorites" onClick={handleFavoritesClick} sx={{ fontFamily: 'Quicksand, sans-serif', fontWeight: 450 }}>Favorites</MenuItem>,
              <MenuItem key="logout" onClick={handleLogoutClick} sx={{ fontFamily: 'Quicksand, sans-serif', fontWeight: 450 }}>Logout</MenuItem>
            ]
          ) : (
            [
              <MenuItem key="register" onClick={onRegisterClick} sx={{ fontFamily: 'Quicksand, sans-serif', fontWeight: 450 }}>Register</MenuItem>,
              <MenuItem key="login" onClick={onLoginClick} sx={{ fontFamily: 'Quicksand, sans-serif', fontWeight: 450 }}>Login</MenuItem>,
            ]
          )}
        </Menu>
      </div>
      <hr style={{border: "1px solid", width: "100%", borderColor: "rgb(221, 221, 221)", marginBottom: "0px"}} />

      {/* Top bar for filters */}
      <div className="listings-page-container">
        <aside className="filter-bar-container" style={{paddingRight: "0px"}}>
          <FilterBar onApplyFilters={handleApplyFilters} brands={brands}/>
        </aside>

        {/* Main content area */}
        <main className="main-content">
          {isFetchingListings ? (
            <div className="spinner-container">
              <BounceLoader size={50} color={"#333"} loading={isFetchingListings} />
            </div>
          ) : (
            <ListingsGrid
              onSingleListingClick={handleSingleListingClick}
              listings={listings}
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              openLoginRegistrationModal={onRegisterClick}
            />
          )}
          <SingleListingModal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            listing={selectedListing}
            openLoginRegistrationModal={openLoginFromSingleListingModal}
          />
        </main>
      </div>
    </div>
  );
};

export default ListingsPage;
