import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useContext, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import FavoritesContext from '../contexts/FavoritesContext';
import { formatPrice, toTitleCase } from '../utils/helpers';
import './css/ListingsGridCard.css'; // Create this file for ListingsGridCard specific styles

const ListingsGridCard = ({ listing, onClick, openLoginRegistrationModal }) => {
    const { favoritedListings, addFavorite, removeFavorite } = useContext(FavoritesContext);

    // Check if the listing is in the favoritedListings set by comparing the listing id
    const favoritedListingsIds = new Set(Array.from(favoritedListings).map((listing) => listing._id));
    const [isFavorited, setIsFavorited] = useState(favoritedListingsIds.has(listing._id));
    const [imageSrc, setImageSrc] = useState(listing.s3_image_urls[0]);
    const isSoldOrOnHold = listing.status !== 'available';
    const [isFavoriteHovered, setIsFavoriteHovered] = useState(false);

    useEffect(() => {
        const favoritedListingsIds = new Set(Array.from(favoritedListings).map((listing) => listing._id));
        setIsFavorited(favoritedListingsIds.has(listing._id));
    }, [favoritedListings, listing._id]);

    const handleFavoriteClick = async (e) => {
        e.stopPropagation();
        if (isFavorited) {
            setIsFavorited(false);
            if (await removeFavorite(listing)) {
                setIsFavorited(false);
            }
        } else {
            if (await addFavorite(listing)) {
                setIsFavorited(true);
            } else {
                // Open the LoginRegistrationModal.js if the user is not logged in
                openLoginRegistrationModal();
            }
        }
    };

    const handleMouseEnter = () => {
        if (listing.s3_image_urls.length > 1) {
            setImageSrc(listing.s3_image_urls[1]);
        }
    };

    const handleMouseLeave = () => {
        setImageSrc(listing.s3_image_urls[0]);
    };

    if (!listing) return null;
    if (listing.s3_image_urls.length <= 1) return null;
    
    let productTitle = toTitleCase(listing.product.model)+ " Bag"
    if (listing.product_variant.size_name) {
        productTitle += " | Size " + toTitleCase(listing.product_variant.size_name);
    }
    return (
        <div onClick={onClick} className="listings-grid-card">
            {isSoldOrOnHold && (
                <div className="status-banner">
                    {listing.status === 'sold' ? 'Sold' : 'On Hold'}
                </div>
            )}
            <LazyLoad height={200} offset={100}>
                <img 
                src={imageSrc} 
                alt={listing.description} 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                />
            </LazyLoad>
            <div
                className="favorite-icon-grid-page"
                onClick={handleFavoriteClick}
                onMouseEnter={() => setIsFavoriteHovered(true)}
                onMouseLeave={() => setIsFavoriteHovered(false)}
            >
                {isFavorited || isFavoriteHovered ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </div>
            <h3>{listing.product.brand}</h3>
            <p className="product-title">{productTitle}</p>
            <div className="grid-price-wrapper">
                <p className="grid-current-price">
                    {formatPrice(listing.current_price)}
                </p>
                {listing.price_history.length > 1 && listing.price_history[0] != listing.current_price && (
                    <p className="grid-original-price">
                        {formatPrice(listing.price_history[0])}
                    </p>
                )}
            </div>
            <p className="condition-text">{toTitleCase(listing.condition_name)} Condition</p>  
        </div>
    );
};

export default ListingsGridCard;