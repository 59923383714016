import React, { useEffect, useState } from 'react';
import FilterDropdown from './FilterDropdown';
import './css/FilterBar.css';

const FilterBar = ({ onApplyFilters, brands }) => {
  const [sortOrder, setSortOrder] = useState('Newest Arrivals'); // Default to 'Newest Arrivals'
  const [minMaxPrice, setMinMaxPrice] = useState({ min: '', max: '' });
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);

  // Add state for dropdown visibility
  const [dropdownVisibility, setDropdownVisibility] = useState({
    sort: false,
    price: false,
    brands: false,
    conditions: false,
    colors: false,
    vendors: false,
  });

  const applyFilters = () => {
    const filters = {
      sortOrder: sortOrder,
      minPrice: minMaxPrice.min,
      maxPrice: minMaxPrice.max,
      brands: selectedBrands,
      conditions: selectedConditions,
      colors: selectedColors,
      vendors: selectedVendors,
    };
    onApplyFilters(filters);
  };

  const resetFilters = () => {
    setSortOrder('Newest Arrivals'); // Reset to 'Newest Arrivals'
    setMinMaxPrice({ min: '', max: '' });
    setSelectedBrands([]);
    setSelectedConditions([]);
    setSelectedColors([]);
    setSelectedVendors([]);
    // Close all dropdowns
    setDropdownVisibility({
      sort: false,
      price: false,
      brands: false,
      conditions: false,
      colors: false,
      vendors: false,
    });
    applyFilters();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFeedbackClick = () => {
    // Open new tab to feedback form
    window.open('https://forms.gle/wMDzW2LR56ktznpk8', '_blank');
  };

  const toggleDropdown = (dropdown) => {
    setDropdownVisibility((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      applyFilters();
    }, 300); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [sortOrder, minMaxPrice, selectedBrands, selectedConditions, selectedColors, selectedVendors]);

  return (
    <div className="filter-bar-components">
      <FilterDropdown
        label="Sort By"
        options={['Newest Arrivals', 'Price: Low to High', 'Price: High to Low']}
        selectedOptions={[sortOrder]}
        setSortOrder={setSortOrder}
        showOptions={dropdownVisibility.sort}
        toggleDropdown={() => toggleDropdown('sort')}
      />
      <FilterDropdown 
        label="Price" 
        minMaxPrice={minMaxPrice}
        setMinMaxPrice={setMinMaxPrice}
        showOptions={dropdownVisibility.price}
        toggleDropdown={() => toggleDropdown('price')}
      />
      <FilterDropdown 
        label="Brands" 
        options={brands}
        selectedOptions={selectedBrands}
        setSelectedOptions={setSelectedBrands}
        showOptions={dropdownVisibility.brands}
        toggleDropdown={() => toggleDropdown('brands')}
      />
      <FilterDropdown 
        label="Condition" 
        options={['Pristine', 'Excellent', 'Very Good', 'Good', 'Fair', 'Poor']}
        selectedOptions={selectedConditions}
        setSelectedOptions={setSelectedConditions}
        showOptions={dropdownVisibility.conditions}
        toggleDropdown={() => toggleDropdown('conditions')}
      />
      <FilterDropdown 
        label="Colors"
        options={['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Pink', 'Purple', 'Brown', 'Black', 'White', 'Grey', 'Neutrals']}
        selectedOptions={selectedColors}
        setSelectedOptions={setSelectedColors}
        showOptions={dropdownVisibility.colors}
        toggleDropdown={() => toggleDropdown('colors')}
      />
      <FilterDropdown
        label="Vendors"
        options={['The RealReal', 'Farfetch', 'Fashionphile', 'ReBag']}
        selectedOptions={selectedVendors}
        setSelectedOptions={setSelectedVendors}
        showOptions={dropdownVisibility.vendors}
        toggleDropdown={() => toggleDropdown('vendors')}
      />
      <button className="reset-button" onClick={resetFilters}>Reset Filters</button>

      <button className="feedback-button" onClick={handleFeedbackClick}>Give Feedback!</button>
      
    </div>
  );
};

export default FilterBar;