import React from 'react';
import './css/ConditionScale.css';

const ConditionScale = ({ selectedCondition }) => {
  const conditions = ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent', 'Pristine'];

  return (
    <div>
        <span className="condition-text-label">Condition</span>
        <div className="condition-scale">
          {conditions.map((cond, index) => (
            <div key={index} className="condition-item">
              <div className={`dot ${cond === selectedCondition ? 'active' : ''}`}></div>
              <span className="condition-label">{cond}</span>
            </div>
          ))}
        </div>
    </div>
  );
};

export default ConditionScale;
