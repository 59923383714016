// src/utils/helpers.js
export const formatPrice = (price) => {
    const options = {
        style: 'currency',
        currency: 'USD',
    };

    // Check if the price has a fractional part
    if (price % 1 !== 0) {
        options.minimumFractionDigits = 2;
        options.maximumFractionDigits = 2;
    } else {
        options.minimumFractionDigits = 0;
        options.maximumFractionDigits = 0;
    }

    return new Intl.NumberFormat('en-US', options).format(price);
};

export const formatCondition = (condition) => {
    return condition.toUpperCase();
};

export const toTitleCase = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
};

export const NUM_SEE_COMPS_TRIGGERED_THRESHOLD = 3;

export const MAX_NUMBER_OF_DESCRIPTION_LINES = 10;

export const FormType = {
    LOGIN: 'login',
    REGISTER: 'register',
    FORGOT_PASSWORD: 'forgot_password',
    RESET_PASSWORD: 'reset_password',
  };

