import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { MAX_NUMBER_OF_DESCRIPTION_LINES, toTitleCase } from '../utils/helpers';
import './css/SingleListingModalDescription.css'; // Create and import this CSS file

const SingleListingModalDescription = ({ listing, isExpanded, setIsExpanded }) => {
    useEffect(() => {
        // Reset the isExpanded state when the listing prop changes
        setIsExpanded(true);
    }, [listing, setIsExpanded]);

    if (!listing) return null;

    let description = listing.description;
    let splitToken = '';

    // Switch on listing.vendor
    switch (listing.vendor) {
        case 'the realreal':
            splitToken = '.,';
            break;
        case 'fashionphile':
            splitToken = '.';
            description = JSON.parse(description).description;
            break;
        case 'rebag':
            splitToken = ',';
            break;
        case 'farfetch':
            splitToken = ',';
            description = description.replace(/dominant_color_family\s*:\s*[^,]+,?/g, '');
            description = description.replace(/dominant_material\s*:\s*[^,]+,?/g, '');
            break;
        default:
            return null;
    }

    const descriptionArray = description.split(splitToken).map(item => item.trim()).filter(item => item !== '').slice(0, MAX_NUMBER_OF_DESCRIPTION_LINES);

    // Find index of element in array with "Store Location:" and remove it and rest of the elements
    const storeLocationIndex = descriptionArray.findIndex(item => item.toLowerCase().includes('accessories:'));
    if (storeLocationIndex !== -1) {
        descriptionArray.splice(storeLocationIndex);
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="collapsible-container">
            <div onClick={toggleExpand} className="toggle-button">
                <span>Description</span>
                <span className={`arrow ${isExpanded ? 'expanded' : ''}`} style={{ marginLeft: '16px' }}>
                    {'>'}
                </span>
            </div>
            <CSSTransition
                in={isExpanded}
                timeout={300}
                classNames="description"
                unmountOnExit
            >
                <ul className="description-list">
                    {descriptionArray.map((item, index) => (
                        <li key={index}>{toTitleCase(item)}</li>
                    ))}
                </ul>
            </CSSTransition>
        </div>
    );
};

export default SingleListingModalDescription;
