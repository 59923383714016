import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import './css/AuthForm.css';

const AuthForm = ({ fields, apiCallback, onSuccess, title, subtitle, toggleForm, toggleText, submissionLabel, extraButtons }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setError('');
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setError('');
    e.preventDefault();
    try {
      const result = await apiCallback(formData);
      if (result.success) {
        onSuccess(result.data);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleToggle = () => {
    setFormData({});
    setError('');
    toggleForm();
  };

  return (
    <div className="auth-container">
      <h2 className="auth-container-title">{title}</h2>
      <h3 className="auth-container-subtitle">{subtitle} <a href="#" className="link-no-underline" onClick={handleToggle}>{toggleText}</a></h3>
      <form onSubmit={handleSubmit}>
      {fields.map((field) => (
        <div className="form-group" key={field.name}>
          <label>{field.label}:</label>
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name] || ''}
            onChange={handleChange}
            required={field.required}
          />
          {field.name === 'newPassword' && (
            <div className="password-strength-wrapper">
            <PasswordStrengthBar
                password={formData[field.name] || ''}
                />
            </div>
          )}
        </div>
      ))}
        {error && <p className="error">{error}</p>}
        <button className="auth-form-button" type="submit">{submissionLabel}</button>
      </form>
      {extraButtons && extraButtons.map((button, index) => (
        <button
          key={index}
          className={button.className}
          onClick={button.onClick}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

AuthForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool,
    })
  ).isRequired,
  apiCallback: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  toggleForm: PropTypes.func.isRequired,
  toggleText: PropTypes.string.isRequired,
  submissionLabel: PropTypes.string.isRequired,
  extraButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default AuthForm;