import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import React, { createContext, useContext, useEffect, useState } from 'react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        // Check localStorage for user information on component mount
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedStoredUser = JSON.parse(storedUser);
            setUser(parsedStoredUser);
            setLogoutTimer(parsedStoredUser.access_token);
        }
    }, []);

    const setLogoutTimer = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const expiresIn = decodedToken.exp * 1000 - Date.now();
            if (expiresIn > 0) {
                setTimeout(() => {
                    logout();
                }, expiresIn);
            } else {
                logout(); // Token already expired
            }
        } catch (error) {
            logout(); // Fallback to logout in case of an error
        }
    };

    const register = async (formData) => {
        try {
            if (formData["newPassword"] != formData["confirmPassword"]) {
                return { success: false, error: "Passwords do not match." };
            }
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.post(`${apiUrl}/register`, formData);
            if (response.status === 200) {
                const { access_token, user_id } = response.data;
                const email = formData["email"];
                localStorage.setItem('user', JSON.stringify({ id: user_id, email, access_token }));
                setUser({ id: user_id, email, access_token });
                setLogoutTimer(access_token);
                return { success: true, data: response.data };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.response.data.error };
        }
    };

    const login = async (formData) => {
        try {
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.post(`${apiUrl}/login`, formData);
            if (response.status === 200) {
                const { access_token, user_id } = response.data;
                const email = formData["email"];
                localStorage.setItem('user', JSON.stringify({ id: user_id, email, access_token }));
                setUser({ id: user_id, email, access_token });
                setLogoutTimer(access_token);
                return { success: true, data: response.data };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.response.data.error };
        }
    };

    const forgotPassword = async (formData) => {
        try {
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.post(`${apiUrl}/forgot-password`, formData);
            if (response.status === 200) {
                return { success: true, data: response.data };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.response.data.error };
        }
    };

    const resetPassword = async (formData) => {
        try {
            if (formData["newPassword"] != formData["confirmPassword"]) {
                return { success: false, error: "Passwords do not match." };
            }
            const apiUrl = process.env.REACT_APP_API_SERVICE_URL
            const response = await axios.post(`${apiUrl}/forgot-password`, formData);
            if (response.status === 200) {
                return { success: true, data: response.data };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.response.data.error };
        }
    };


    // In your UserContext or a similar context:
    const googleLogin = async (token) => {
        try {
            const response = await fetch('/api/google-login', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
            if (response.status === 200) {
                // Handle successful login (e.g., set user state, token, etc.)
                const { access_token, user_id, email } = response.data;
                localStorage.setItem('user', JSON.stringify({ id: user_id, email, access_token }));
                setUser({ id: user_id, email, access_token });
                setLogoutTimer(access_token);
                return { success: true, data: response.data };
            } else {
                return { success: false, error: response.data.error };
            }
        } catch (error) {
            return { success: false, error: error.response.data.error };
        }
    };


    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
        window.location.reload(); // Force the page to refresh
    };

    return (
        <UserContext.Provider value={{ user, login, logout, register, forgotPassword, resetPassword, googleLogin }}>
            {children}
        </UserContext.Provider>
    );
};
